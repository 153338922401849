@use '../colors' as colors;

html,
body {
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;

  overflow: hidden;
  font-size: 14px;

  color: colors.$text-color;
  background-color: colors.$background-color;

  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

* {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}

h1 {
  font-size: 25px;
}

h2 {
  font-size: 20px;
}

hr {
  border-color: colors.$border-color;
  opacity: 1;
}

app-root {
  height: 100%;
}

a {
  text-decoration: none;

  color: colors.$accent-color;

  transition: color 0.25s ease-in-out;

  &:hover {
    color: colors.$primary-color;
  }
}
