@use '../colors' as colors;

.text-normal {
  color: colors.$text-color !important;
}

.text-success {
  color: colors.$success-color !important;
}

.text-danger {
  color: colors.$danger-color !important;
}

.text-primary {
  color: colors.$primary-color !important;
}

.text-accent {
  color: colors.$accent-color !important;
}

.text-muted {
  color: colors.$text-muted-color !important;
}

.text-skeleton {
  color: colors.$skeleton-color !important;
}

.text-new-line {
  white-space: pre-wrap;
}

.text-multiline-content {
  white-space: pre-line;
}

@for $i from 1 through 10 {
  .text-truncate-multiline-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
