@use '../colors' as colors;

table {
  td, th {
    vertical-align: middle;
  }

  thead {
    td, th {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &.table-hovered {
    tbody {
      tr:hover {
        background-color: colors.$highlighted-background-color;
      }
    }
  }
}

td.cell-fit-content {
  width: 0;
  min-width: fit-content;
}
