@use '../colors' as colors;

.chip {
  display: inline-block;

  border-radius: 16px;
  padding: 0.25rem 1rem;

  color: colors.$text-color;

  background-color: #fff;
  border: 1px solid colors.$border-color;
}
