@use '../colors' as colors;
@use '@angular/material' as mat;

@include mat.core();

$primary-palette-colors: (
  50: #fbfbfb,
  100: #f6f6f6,
  200: #f2f2f2,
  300: #e8e8e8,
  400: #c6c6c6,
  500: #a8a8a8,
  600: #7e7e7e,
  700: #6a6a6a,
  800: colors.$primary-color,
  900: #282828,
  contrast: (
    50: colors.$dark-primary-text-color,
    100: colors.$dark-primary-text-color,
    200: colors.$dark-primary-text-color,
    300: colors.$dark-primary-text-color,
    400: colors.$dark-primary-text-color,
    500: colors.$dark-primary-text-color,
    600: colors.$light-primary-text-color,
    700: colors.$light-primary-text-color,
    800: colors.$light-primary-text-color,
    900: colors.$light-primary-text-color,
  )
);

$accent-palette-colors: (
  50: #e3f1ee,
  100: #bcded4,
  200: #94c9b9,
  300: #70b39f,
  400: #5ca28c,
  500: #51927b,
  600: colors.$accent-color,
  700: #447562,
  800: #3d6555,
  900: #31493d,
  contrast: (
    50: colors.$dark-primary-text-color,
    100: colors.$light-primary-text-color,
    200: colors.$light-primary-text-color,
    300: colors.$light-primary-text-color,
    400: colors.$light-primary-text-color,
    500: colors.$light-primary-text-color,
    600: colors.$light-primary-text-color,
    700: colors.$light-primary-text-color,
    800: colors.$light-primary-text-color,
    900: colors.$light-primary-text-color,
  )
);

$primary-palette: mat.m2-define-palette($primary-palette-colors, 800);
$accent-palette: mat.m2-define-palette($accent-palette-colors, 600);

$gomoque-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $primary-palette,
        accent: $accent-palette,
      ),
      typography: mat.m2-define-typography-config(),
      density: 0,
    )
);

@include mat.core-theme($gomoque-theme);

@include mat.button-theme($gomoque-theme);
@include mat.input-theme($gomoque-theme);
@include mat.form-field-theme($gomoque-theme);
@include mat.checkbox-theme($gomoque-theme);
@include mat.menu-theme($gomoque-theme);
@include mat.progress-spinner-theme($gomoque-theme);
@include mat.button-toggle-theme($gomoque-theme);

.mat-mdc-checkbox {
  .mdc-label {
    text-align: left !important;
  }
}

.mat-mdc-progress-spinner {
  display: inline-block !important;
}
